<template>
  <div class="home publicVCenter">
    <img src="@/assets/image/phone/img2.png" class="bg" alt="嗨喵悦动,嗨喵互动,嗨喵手机端"/>
    <div class="applyBtn" @click="toRegister"></div>
    <div class="btnGroup">
      <div class="btn" @click="toRegister"></div>
      <div class="btn"><a href="tel:400-082-2298"></a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'phoneHome',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇宏包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    toRegister() {
      this.$router.push({
        path: '/phoneRegister',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  position: relative;
  flex-direction: column;
  .bg {
    width: 100%;
  }
  .applyBtn {
    width: 85vw;
    height: 17vw;
    background-image: url("../../assets/image/phone/img8.png");
    background-size: 100% 100%;
    position: absolute;
    top: 109vw;
  }
  .btnGroup {
    width: 100%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 2vw;
    .btn {
      width: 41vw;
      height: 13vw;
      background-size: 100% 100%;
      &:nth-child(1) {
        background-image: url("../../assets/image/phone/img6.png");
      }
      &:nth-child(2) {
        background-image: url("../../assets/image/phone/img7.png");
      }
      a {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
</style>
